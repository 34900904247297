// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1707120272932.2205";
}
// REMIX HMR END

import { Form } from '@remix-run/react';
import { auth } from '~/services/authentication/auth.server';
export let loader = async ({
  request
}) => {
  return await auth.isAuthenticated(request, {
    successRedirect: '/dashboard'
  });
};
export let action = async ({
  request
}) => {
  // The success redirect is required in this action, this is where the user is
  // going to be redirected after the magic link is sent, note that here the
  // user is not yet authenticated, so you can't send it to a private page.
  await auth.authenticate('email-link', request, {
    successRedirect: '/login',
    // If this is not set, any error will be throw and the ErrorBoundary will be
    // rendered.
    failureRedirect: '/login'
  });
};

// app/routes/login.tsx
export default function Login() {
  return <section>
     <Form action="/auth/google" method="post">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                SurgeOps    
            </a>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Sign in to your account using Google
                    </h1>
                        <button type="submit" className="w-full text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">Login with Google</button>
                    </div>
                </div>
        </div>
    </Form>
    </section>
  //   {magicLinkSent ? (
  //     <p>
  //       Successfully sent magic link{' '}
  //       {magicLinkEmail ? `to ${magicLinkEmail}` : ''}
  //     </p>
  //   ) :null}

  //     <>

  //       <h1>Log in to your account.</h1>
  //       <div>
  //         <label htmlFor="email">Email address</label>
  //         <input id="email" type="email" name="email" required />
  //       </div>
  //       <button>Email a login link</button>
  //     </>

  // </Form>
  ;
}
_c = Login;
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;